<template>
  <v-autocomplete
    v-model="innerValue"
    color="primary"
    item-text="name"
    item-value="id"
    multiple
    return-object
    :items="filteredItems"
    :loading="loading"
    :label="label"
    :error-messages="errorMessages"
    :menu-props="{ offsetY: true, overflowY: true }"
  >
    <template #selection="{ item, index }">
      {{ index === 0 ? $tc("global.shift_selected", innerValue.length, { count: innerValue.length }) : "" }}
    </template>
    <template #item="data">
      <v-list-item-avatar :color="data.item.color" class="mr-2" :size="32" :class="'white--text font-weight-bold'">
        <span class="white--text font-weight-bold">
          {{ data.item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="body-2">{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="data.item.shiftType === 'FLEXIBLE'">
          {{ $t("time.calendar.total_work_time") }}
          <strong>
            {{ $moment.utc($moment.duration(data.item.lengthInMinutes, "minutes").asMilliseconds()).format("HH:mm") }}
          </strong>
          {{ $t("time_duration.hour") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>{{ data.item.beginTime }} - {{ data.item.endTime }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  import { SHIFTS } from "../gql";
  import { SHIFT_TYPES } from "@/helpers/enums";

  export default {
    inheritAttrs: false,
    name: "shift-pickers",
    props: {
      value: [String, Object, Array],
      shiftType: String,
      errorMessages: Array
    },
    data: () => ({
      loading: false,
      items: []
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      label() {
        return this.shiftType === "OFF_DAY"
          ? this.$t("shift.off_day")
          : this.shiftType === "BREAK"
          ? this.$t("shift.break_day")
          : this.$t("global.shifts_type", [`${this.$t("shift_types." + this.shiftType)}`]);
      },
      filteredItems() {
        return this.shiftType === SHIFT_TYPES.OFF_DAY || this.shiftType === SHIFT_TYPES.BREAK
          ? this.items
          : this.items.filter(item => item.shiftType === this.shiftType);
      }
    },
    watch: {
      shiftType() {
        this.innerValue = [];
      }
    },
    methods: {
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: SHIFTS,
            fetchPolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.items = data.shifts.map(shift => ({ ...shift, referenceShift: shift.id }));
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .then(() => (this.loading = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
